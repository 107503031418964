import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'gatsby';

import SEO from '../components/Seo';
import Title from '../components/Title';

interface SuccessPageProps {
  location?: {
    state?: {
      amount?: string;
      category?: string;
      comment?: string;
    };
  };
}

const SuccessPage = ({ location }: SuccessPageProps): React.ReactNode => {
  const amount = location?.state?.amount;
  const category = location?.state?.category;
  const comment = location?.state?.comment;

  return (
    <Grid container spacing={4} direction="column" alignItems="center">
      <SEO title="Success" />

      <Grid item xs={12}>
        <Title />
      </Grid>

      <Grid container item xs={12} sm={6} md={5} justify="center">
        <Typography align="center">Your expense was added successfully!</Typography>
      </Grid>

      {amount && (
        <Grid container item xs={12} sm={6} md={5} justify="center">
          <Typography align="center">Amount: {amount}</Typography>
        </Grid>
      )}

      {category && (
        <Grid container item xs={12} sm={6} md={5} justify="center">
          <Typography align="center">Category: {category}</Typography>
        </Grid>
      )}

      {comment && (
        <Grid container item xs={12} sm={6} md={5} justify="center">
          <Typography align="center">Comment: {comment}</Typography>
        </Grid>
      )}

      <Grid container item xs={12} sm={6} md={5}>
        <Button variant="contained" color="primary" component={Link} to="/add" fullWidth>
          Add another expense
        </Button>
      </Grid>

      <Grid container item xs={12} sm={6} md={5}>
        <Button color="primary" size="large" fullWidth component={Link} to="/logout">
          Log out
        </Button>
      </Grid>
    </Grid>
  );
};

export default SuccessPage;
